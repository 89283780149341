import React from "react";
import {string, bool, oneOf, objectOf, any} from "prop-types";

const getTitle = (level, styles, text, style) => {
  if (level === 1) {
    return (
      <h1 className={`${styles}`} style={style}>
        {text}
      </h1>
    );
  }

  if (level === 2) {
    return (
      <h2 className={`${styles}`} style={style}>
        {text}
      </h2>
    );
  }

  if (level === 3) {
    return (
      <h3 className={`${styles}`} style={style}>
        {text}
      </h3>
    );
  }

  if (level === 4) {
    return (
      <h4 className={`${styles}`} style={style}>
        {text}
      </h4>
    );
  }

  return null;
};

const Title = ({
  className,
  level,
  withBlueSymbol,
  symbol,
  children,
  style,
}) => {
  const styles = `${className} font-sans font-bold mb-6 lg:mb-8`;

  const EndSymbol = !withBlueSymbol ? (
    <></>
  ) : (
    <span className="text-primary">{symbol}</span>
  );

  const Text = (
    <>
      {children}
      {EndSymbol}
    </>
  );

  return getTitle(level, styles, Text, style);
};

Title.propTypes = {
  className: string,
  children: string,
  level: oneOf([1, 2, 3, 4]),
  withBlueSymbol: bool,
  symbol: string,
  style: objectOf(any),
};

Title.defaultProps = {
  className: "",
  children: "",
  level: 1,
  withBlueSymbol: false,
  symbol: ".",
  style: {},
};

export default Title;
